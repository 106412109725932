<template>
  <div>
    <BaseContainer class="pt-24 pb-16 relative z-20 min-h-screen">
      <BaseHeadingAndText heading="Private presale" headingSize="6xl" headingColor="cdlpink" textSize="xl">
        wanna buy some tralala?
      </BaseHeadingAndText>
    </BaseContainer>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/common/Footer"
export default {
  name: "PrivatePresale",
  components: {
    Footer
  },
  data() {
    return {}
  },
}
</script>
